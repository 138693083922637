.loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid rgb(161, 31, 31);
    border-bottom: 8px solid rgb(161, 31, 31);
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .fu-component {
    position: relative;
  
    .p-fileupload {
      position: relative;
      z-index: 1;
    }
  
    .spinner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  