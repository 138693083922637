.fh{
  height: 90vh;
}

.blue-box {
    border-radius: 20px;
    background-color: #262C5F;
    display: table-cell;
    vertical-align: middle;
}

.error-box {
  box-shadow: inset 0 0 1em red !important;
  border-color: red !important;
}

.outer {
    display: flex;
    min-height: 80vh;
    flex-direction: column;
    justify-content: center;
}

.odf-shine {
    background-color: hsl(350, 68%, 36%) !important;
    color: hsl(260, 85%, 97%) !important;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 1.2rem;
    border: 1px solid hsl(350, 68%, 36%) !important;
    cursor: pointer;
  }
  
  .odf-shine:hover {
    animation-name: shine !important;
    animation-duration: 320ms !important;
    background-color: hsl(350, 68%, 44%) !important;
    border-color: hsl(350, 68%, 44%)!important;
  }
  
  @keyframes shine {
    0% {
      background: linear-gradient(
        120deg,
        hsl(260, 85%, 95%) 0%,
        hsl(350, 68%, 44%) 25%
      );
    }
  
    25% {
      background: linear-gradient(
        120deg,
        hsl(350, 68%, 44%) 0%,
        hsl(260, 85%, 95%) 25%,
        hsl(350, 68%, 44%) 50%
      );
    }
  
    50% {
      background: linear-gradient(
        120deg,
        hsl(350, 68%, 44%) 0%,
        hsl(260, 85%, 95%) 50%,
        hsl(350, 68%, 44%) 75%
      );
    }
  
    75% {
      background: linear-gradient(
        120deg,
        hsl(350, 68%, 44%) 0%,
        hsl(260, 85%, 95%) 75%,
        hsl(350, 68%, 44%) 100%
      );
    }
  
    100% {
      background: linear-gradient(
        120deg,
        hsl(350, 68%, 44%) 0%,
        hsl(260, 85%, 95%) 100%
      );
    }
  }

  .odf-button {
    background-color: white !important;
    color: black !important;
    border: 0 !important;
    height: 43px !important;
    width: 120px;
  }

  .odf-border:hover {
    background-color: white !important;
    border-radius: 0 !important;
    font-weight: bold;
    height: 43.5px;
  }

  
  .odf-border::after{
    content: '';
    position: absolute !important;
    width: 0px !important;
    height: 5px !important;
    left: 50% !important;
    top: 0 !important;
    background-color: rgb(161, 31, 31) !important;
    transition: all ease-in-out .2s !important;
  }

  .odf-border:hover::after{
    width: 100% !important;
    left: 0 !important;
  }

  .menubar-avatar{
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
  }

  .menubar-avatar:hover{
    font-weight: bold;
    animation-name: shine !important;
    animation-duration: 320ms !important;
    background-color: hsl(350, 68%, 44%);
  }

  .filter-element{
    width: 80% !important;
    border-radius: 0 !important;
    display: inline-block !important;
  }

  .table-header {
    border-radius: 10px 10px 0 0;
    border: 2px solid #E0E0E0;
    background-color: #F4F4F4;
    width: 100%;
    height: 50px;
    text-align: center;
    font-size: xx-large;
    font-family:Arial, Helvetica, sans-serif;
    padding-top: 0px;
    padding-bottom: 5px;
  }

  .f-w {
    width: 100%;
  }

  .bd-bell {
    color: gold;
  }

  .textbox-grey {
    height: 30px;
    text-align: end;
    padding: 2px 10px 0 10px;
    margin: 0 5px 0 5px;
    width: fit-content;
    background-color: #F4F4F4;
    border: 1px solid black;
    border-radius: 5px;
  }

  .textbox-white {
    height: 30px;
    text-align: end;
    padding: 2px 10px 0 10px;
    margin: 0 5px 0 5px;
    width: fit-content;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;
  }

  .dropdown-white {
    height: 30px;
    margin: 0 5px 0 5px;
    width: fit-content;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;
  }

  .article-card-transparent {
    background-color: transparent !important;
    border: none !important;
    color: black !important;
  }

  .table-row-yellow {
    background-color: yellow !important;
  }

  .table-row-red {
    background-color: rgb(255, 0, 0) !important;
  }

  // .regular-button{
  //   background-color: rgb(161, 31, 31) !important;
  //   border-color: rgb(173, 68, 68) !important;
  // }

  // .regular-button:hover {
  //   background-color: rgb(151, 59, 59) !important;
  //   border-color: rgb(151, 59, 59) !important;
  // }
  
  .article-card {
    &:hover {
      border: 3px solid hsl(350, 68%, 44%);
    }
  }

  .border-coll {
    border: 1px solid;
    border-collapse: collapse;
  }

  .custom-sb {
    width: 100%;
    height: 30px;
    font-weight: bold;
  }

  .sb-on {
    background-color: hsl(350, 68%, 44%);
    color: white;
  } 
  
  .sb-off {
    background-color: white;
    color: black;
    &:hover {
      background-color: hsl(350, 68%, 44%);
    }
  }

  .sb-l {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-radius: 5px 0 0 5px;
  }

  .sb-m {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }

  .sb-r {
    border: 1px solid black;
    border-radius: 0 5px 5px 0;
  }

  .div-label {
    height:45px;
    padding: 5px 0;
  }

  .min-h-8rem {
    min-height: 8rem;
  }

  .link-like-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: rgb(161, 31, 31); /* Link color */
    cursor: pointer;
  }

  .link-like-button:hover {
    color: rgb(170, 71, 71);
    text-decoration: underline;
  }
  