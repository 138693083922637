body {
    margin: 0;
}

.app {
    width: 100%;
    height: 100%;
}

.main-wrapper {
    display: flex;
    min-height: 90vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFF4F6;
    overflow-x: hidden;
  }

.footer {
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    padding: 10px 0 10px 10px;
    font-style: italic;
    background-color: white;
    margin-bottom: 10px;
  }

.route-content {
    background-color: white;
    border-radius: 3px;
    border: 1px solid #c8c8c8;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 10px 10px 10px;
  }