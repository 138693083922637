:root {
  --primary: #437ab2;
  --secondary: white;
  --tertiary: rgb(245, 245, 255);
}


.p-menuitem-link:hover {
  background-color: white !important;
  border-radius: 0 !important;
  font-weight: bold;
  height: 43.5px;
}

.p-menuitem-link::after {
  content: '';
  position: absolute !important;
  width: 0px !important;
  height: 5px !important;
  left: 50% !important;
  top: 0 !important;
  background-color: rgb(161, 31, 31) !important;
  transition: all ease-in-out .2s !important;
}

.p-menuitem-link:hover::after {
  width: 100% !important;
  left: 0 !important;
}

.p-fluid .p-calendar .p-inputtext {
  width: 100% !important;
  border-radius: 0 !important;
}

.p-togglebutton {
  background-color: white !important;
  color: black !important
}

.p-button,
.p-datatable .p-sortable-column.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: rgb(161, 31, 31) !important;
  border-color: rgb(170, 71, 71) !important;
}

.p-button:hover {
  background-color: rgb(170, 71, 71) !important;
  border-color: rgb(170, 71, 71) !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 0 !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: rgb(170, 71, 71) !important;
  border-color: rgb(170, 71, 71) !important;
}

.p-button-label,.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right,.p-button.p-button-text {
  color: white !important;
}

.p-checkbox-mod {
  width: 35px !important;
  height: 35px !important;
  display: block !important;
}

.p-carousel .p-carousel-content .p-carousel-next,.p-carousel .p-carousel-content .p-carousel-prev{
  background: #a80000 !important;
  color: white !important;
  &:hover {
    background: white !important;
    color: #a80000 !important;
    border: 1px solid #a80000 !important;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
  background-color: #a80000 !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button{
  border: 1px solid #a80000 !important;
  background-color: white !important;
  &:hover {
    background-color: #a80000 !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #a80000 !important;
  background: #a80000 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #a80000 !important;
  background: #a80000 !important;
}