.error-font-1 {
    font-size: 40px;
    font-family:Arial, Helvetica, sans-serif;
    color: white;
    text-align: center;
}

.error-font-2 {
    font-size: 28px;
    font-family:Arial, Helvetica, sans-serif;
    color: white;
    text-align: center;
}

.text-red{
    color: rgb(161, 31, 31);
}

.vertical-ellipsis{
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 5; /*define how many line you want to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
   }